@import '../../../styles/styles';

@mixin menuTransition {
  transition: 0.3s ease all;
}

.menuBody {
  background-color: $white;
  box-shadow: $standardBoxShadow;
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 0.5rem);
  padding: 1rem;
  min-width: 240px;

  a {
    color: $cyprus !important;
    font-weight: 600;
    text-decoration: none;
    font-size: 14px;
    display: block;
  }

  &:not(.isMobile) {
    padding: 1rem 1rem 5px;

    // Links are 40px in height
    a {
      min-height: 40px;
      padding: 0 1rem;
      line-height: 40px;

      &:hover {
        background-color: $suggestionHilight;
      }

      &:nth-of-type(2) {
        margin-bottom: 6px;
      }

      &:last-of-type {
        border-radius: 0px 0px 4px 4px;
        line-height: 36px;

        &:before {
          height: 2px;
          display: block;
          background: #ebebeb;
          position: relative;
          content: '';
          top: -4px;
        }
      }
    }

    .userLinks {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  &.isMobile {
    position: inherit;
    left: 0;
    top: 0;
    box-shadow: none;
    border-radius: 0px;
    min-width: auto;
    padding: 1.25rem 0;
    border: 2px solid $divider;
    border-left: none;
    border-right: none;

    a {
      font-size: 16px;
      @include mediaQuery(max, $tablet) {
        font-size: 18px;
      }

      &:not(:last-of-type) {
        padding: 0 0 calc(0.75rem + 5px) !important;
      }

      &:last-of-type {
        padding-bottom: 0px;
      }
    }

    .names {
      flex: 0 1 auto;
      max-width: 250px;
      word-break: break-all;

      h5 {
        font-size: 16px;
        font-weight: 600;

        @include mediaQuery(max, $tablet) {
          font-size: 18px;
        }
      }
    }

    .menuMain {
      @include menuTransition;
      display: flex;
      padding-bottom: 0px;
    }

    .userLinks {
      display: none;
    }
  }

  &.expanded {
    .menuMain {
      margin-bottom: 1.25rem;
    }

    .userLinks {
      display: block;
    }
  }
}

.menuMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 0.5rem;

  p {
    margin: 0px;
    padding: 0px;
  }
}

.avatar {
  margin-right: 15px;
  margin-left: 20px;
}

.names {
  flex: 1;

  h5 {
    font-size: 18px;
    font-weight: 600;
    color: $cyprus;
  }

  p {
    font-size: 15px !important;
    font-weight: 400;
    color: $bodyText;
    line-height: 24px !important;
  }
}

.caret {
  @include menuTransition;
  transform: translateY(-50%) rotate(180deg) scale(1.7);
  overflow: visible !important;
  margin-left: auto;
  margin-right: 4px;
  align-self: center;
  margin-top: 10px;

  &.focused {
    transform: translateY(-50%) scale(1.7);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn ease 0.3s;
}

.userAvatarImgContainer {
  margin-right: 15px;
  margin-left: 20px;
}

.userAvatarImg {
  width: auto;
  height: 3rem;
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
}
