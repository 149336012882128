@import '../../styles/styles';

@mixin desktopHover() {
  @include mediaQuery(min, $tablet) {
    &:hover {
      @content;
    }
  }
}

.appFooter {
  align-items: flex-start;
  background-color: $blue-extra-dark;
  display: flex;
  flex-direction: column;
  padding: 36px 24px;
  overflow-x: hidden;

  @include mediaQuery(min, $mobile) {
    padding: 36px 48px;
  }
  @include mediaQuery(min, $lgTablet) {
    padding: 48px;
  }
  @include mediaQuery(min, 1196px) {
    padding: 48px calc(50% - 550px);
  }

  // Styling specific to the booking flow
  :global(body.Booking) & {
    align-items: center;
    background-color: $white;
    border-top: 1px solid $divider;
    margin-top: 4rem;
    padding: 40px 0;
    text-align: center;

    .siteLinks {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }

    .footerBottomLink {
      font-size: 14px;
      font-weight: 300;
      padding: 0 8px;
    }

    @include mediaQuery(min, $lgTablet) {
      flex-direction: row;
      justify-content: center;
      margin-top: 0;

      .siteLinks {
        border-left: $casal 1.5px solid;
        margin-left: 18px;
        padding-left: 14px;
        a {
          line-height: 1;
        }
      }
    }

    .footerBottomLink {
      color: $casal;
      padding: 0 6px;
    }
    .footerLogo,
    .divider,
    .footerMainLinks,
    .socialLinks {
      display: none;
    }
  }
}

.noFooter {
  display: none;
}

.footerLogo {
  display: block;
  margin-bottom: 12px;
  width: fit-content;

  @include mediaQuery(min, $lgTablet) {
    margin-bottom: 24px;
  }
}

.footerMainLinks {
  display: flex;
  flex-direction: column;
  margin-bottom: 44px;
  width: 100%;

  .footerCategory {
    border-bottom: 1px solid white;
    padding: 0.25rem 0;
    width: 100%;
  }

  .footerCategoryTitle {
    background-image: url('/assets/icons/icon-chevronDownWhite.svg');
    background-position: right;
    background-repeat: no-repeat;
    color: $white;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 10px 0;
    text-transform: uppercase;

    &.footerCategoryTitleActive {
      background-image: url('/assets/icons/icon-chevronUpWhite.svg');
    }
  }

  .footerLinkWrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 500ms ease;

    &.footerLinkWrapperActive {
      max-height: 20rem;
    }
    &.footerLinkWrapperHidden {
      display: none;
    }
  }

  .footerLink {
    color: $white;
    display: block;
    font-size: 14px;
    line-height: 1rem;
    margin-bottom: 0.725rem;
    padding: 5px 0;
    text-decoration: none;
  }

  @include mediaQuery(min, $tablet) {
    .footerCategoryTitle {
      font-size: 18px;
      line-height: 28px;
    }
  }

  @include mediaQuery(min, $lgTablet) {
    flex-direction: row;
    margin-bottom: 0;

    .footerCategory {
      border: none;
      padding: 0 1.875rem 1rem 0;
      width: 25%;
    }

    .footerCategoryTitle {
      background-image: none;
    }

    .footerLinkWrapper {
      max-height: none;
      margin: 12px 0;
    }

    .footerLink {
      margin-bottom: 1rem;
    }
  }
}

.divider {
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
  height: 1px;
  transform: translateX(-48px);
  width: 100vw;
  @include mediaQuery(min, 1196px) {
    transform: translateX(calc((50% - 550px) * -1));
  }
}

.footerBottomLink {
  color: white;
  font-size: 15px;
  line-height: 27px;
  text-decoration: none;
  &.copyright {
    margin-bottom: 6px;
  }
  @include mediaQuery(min, $lgTablet) {
    font-size: 14px;
    font-weight: 300;
    line-height: 32px;
  }
}

.socialLinks {
  display: flex;
  height: 24px;
  justify-content: space-between;
  margin: 16px 0 24px;
  width: 170px;

  .footerSocial {
    @include desktopHover {
      div:first-child,
      img {
        transition: all 0.125s $standardTransitionTimingFunction;
        transform: scale(110%);
      }
    }
  }

  @include mediaQuery(min, $lgTablet) {
    margin: 42px 0 16px;
    width: 138px;
  }
}

.siteLinks {
  display: flex;
  flex-direction: column;
}

.linkText {
  @include desktopHover {
    text-decoration: underline;
  }
}
