@import '../../styles/styles';

// Only target specific page types
@mixin favoritesPageOnly() {
  header.favoritesPage & {
    @content;
  }
}

@mixin listingPageOnly() {
  header.listingPage & {
    @content;
  }
}

.headerLink {
  color: $blue-dark;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.bannerContainer {
  position: sticky;
  top: 0;
  z-index: 1;
}

.header {
  background-color: $white;
  padding: 1rem 24px 1rem;
  transition: background-color $standardTransition,
    border-color $standardTransition;
  z-index: 7;
  width: 100%;
  border-bottom: 0px solid transparent;

  .header_account_link {
    color: $blue-dark;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }

  .stepTracker {
    font-size: 18px;
    color: $saldova;
    font-weight: 500;
  }

  &.mobileMenuActive {
    z-index: 8;
  }

  &.transparent {
    background-color: transparent;
    margin-bottom: -66px;

    .headerLink {
      color: $white;
    }
  }

  @include mediaQuery(min, 600px) {
    padding: 1rem 48px 1rem;

    &.transparent {
      margin-bottom: -72px;
    }
  }

  &.listingPage {
    position: relative;
  }

  @include listingPageOnly {
    @include mediaQuery(max, 600px) {
      border-bottom: 1px solid $grey-light;
    }
  }

  &.searchPage {
    .searchFields {
      > div > div > input {
        height: 40px;

        @include mediaQuery(min, 864px) {
          min-width: 18rem;
          max-width: 100%;
        }
      }

      @include mediaQuery(min, $searchPageMobile) {
        > :first-child {
          // Search box
          margin-right: 8px;
        }

        > :nth-child(2) {
          // Dates
          min-width: 150px !important;
        }

        > :nth-child(3) {
          // Guests
          min-width: 140px !important;
          width: 140px !important;
        }
      }

      > :nth-child(2) {
        margin-right: 8px;
      }

      @include mediaQuery(max, $searchPageMobile) {
        padding-top: 20px;
        flex-wrap: wrap;

        // Search bar
        > :first-child {
          width: 100%;
          margin-right: 0;
        }

        // Dates & Guests filters
        > :not(:first-child) {
          width: calc(50% - 4px) !important;
          min-width: calc(50% - 4px) !important;
          margin-top: 8px;
          max-height: 40px;
          align-items: center;

          @include searchFilterCopy;
        }
      }
    }

    position: relative;
    transition: none;

    &.mobileMenuActive {
      z-index: 11;
    }

    .desktopNav {
      height: 50px;
      display: flex;
    }

    .desktopSearch {
      @include mediaQuery(min, $searchPageHamburger) {
        display: flex;
      }
    }

    .pagesContainer {
      ul {
        li {
          margin: 0 10px;
        }
      }
    }

    .loginContainer {
      margin-left: 10px;
    }

    .headerLinkTitle {
      padding-right: 0px;
      background-image: none;
    }

    .mobileNav,
    .mobileNav > div:first-child {
      display: flex;

      > :nth-child(2) {
        width: 100%;
      }

      @include mediaQuery(max, $searchPageMobile) {
        flex-wrap: wrap;
        align-items: flex-start;
      }
    }

    .mobileMenu {
      button {
        @include mediaQuery(max, $searchPageMobile) {
          top: 1.08rem;
        }
      }

      &.active {
        button {
          top: 2.5rem;

          @include mediaQuery(max, $searchPageMobile) {
            top: 1.53rem;
          }
          @include mediaQuery(max, $mobile) {
            right: 0vw;
          }
        }
      }
    }

    @include mediaQuery(min, $tablet) {
      position: absolute;
    }

    @include mediaQuery(max, $searchPageMobile) {
      padding: 1rem 1.5rem 1rem;
    }
  }
}

.headerHidden {
  display: none;
}

.headerSticky {
  background-color: $white;
  border-bottom: 1px solid $grey-very-light;
  position: sticky;
  top: 0px;

  .headerLink {
    color: $blue-dark;
  }

  .headerLinkTitle {
    background-image: url('/assets/icons/icon-chevronDownBlue.svg');
  }

  .headerLinkTitleActive {
    background-image: url('/assets/icons/icon-chevronUpBlue.svg');
  }

  &.transparent {
    background-color: $white;

    .headerLink {
      color: $blue-dark;
    }

    .headerLinkTitle {
      background-image: url('/assets/icons/icon-chevronDownBlue.svg');
    }

    .headerLinkTitleActive {
      background-image: url('/assets/icons/icon-chevronUpBlue.svg');
    }
  }
}

.desktopNav {
  align-items: center;
  display: none;
  justify-content: space-between;
  position: relative;

  @include mediaQuery(min, 1025px) {
    display: flex;
  }
}

.desktopSearch {
  align-items: center;
  display: none;
  justify-content: space-between;

  @include mediaQuery(min, 1025px) {
    display: flex;
  }
}

.mobileCta {
  margin-right: 1rem;
}

.logoContainer {
  margin-right: 2rem;

  span {
    left: -10000rem;
    position: absolute;
    top: -10000rem;
  }

  input,
  .searchInput {
    width: 17rem;
    height: 40px;
  }

  svg {
    width: 150px;

    @include mediaQuery(max, $mobile) {
      width: 125px;
    }
  }

  &.searchPage {
    @include mediaQuery(max, $searchPageMobile) {
      width: 125px;
    }
  }
}

.navLinksContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.navLinksContainerFull {
  @extend .navLinksContainer;

  justify-content: space-between;
}

.pagesContainer {
  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;

    li {
      margin: 0 0.5rem;

      @include mediaQuery(max, 960px) {
        margin: 0 0.5rem;
      }
    }
  }
}

.loginContainer {
  margin-left: 0.5rem;
  position: relative;
}

.headerLinkTitle {
  background-color: transparent;
  background-image: url('/assets/icons/icon-chevronDownBlue.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: 15px;
  border: none;
  cursor: pointer;
  display: block;
  padding: 0;
  padding-right: 1.75rem;

  &.transparent {
    background-image: url('/assets/icons/icon-chevronDownWhite.svg');
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.headerLinkTitleActive {
  @extend .headerLinkTitle;
  background-image: url('/assets/icons/icon-chevronUpBlue.svg');

  &.transparent {
    background-image: url('/assets/icons/icon-chevronUpWhite.svg');
  }
}

.menuDropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 30px;
  width: 150px;

  a {
    display: block;
    padding: 5px 0;
  }
}

.menuDropdownActive {
  @extend .menuDropdown;
  display: block;
  background-color: $white;
  border-radius: 4px;
  box-shadow: $standardBoxShadow;
  padding: 0 20px;
  min-height: 100px;

  a {
    padding: 12px 20px;
    min-height: 40px;
    @include verticalAlign;

    &:first-of-type {
      border-radius: 4px 4px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 4px 4px;
    }

    &:hover {
      background-color: $suggestionHilight;
    }
  }

  &.transparent {
    .headerLink {
      color: $blue-dark;
    }
  }
}

.linkWrapper {
  margin: 10px -20px;
}

.hasMobileSearch {
  @include mediaQuery(min, 901px) {
    justify-content: left;
  }

  @include mediaQuery(max, $mobile) {
    justify-content: right;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.mobileNav,
.mobileNav > div:first-child {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @include listingPageOnly {
    @extend .hasMobileSearch;
  }

  @include favoritesPageOnly {
    @extend .hasMobileSearch;
  }

  @include mediaQuery(min, 1025px) {
    display: none;
  }

  .logoContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 0;
    max-width: calc(100% - 3rem);
    position: relative;
    top: 0.125rem;
    z-index: 10;
  }

  &.mobileActive {
    @media (orientation: landscape) {
      .logoContainer {
        background-color: $white;
        width: 100%;
        padding-top: 20px;
        margin-top: -20px;
      }
    }
  }
}

.mobileMenu.mobileMenu.mobileMenu {
  &.active {
    background-color: $white;
    bottom: 0;
    left: 0;
    padding: 6rem 2rem 1rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
    overflow-y: auto;

    @include mediaQuery(min, $mobile) {
      padding: 6rem 3rem 1rem;
    }

    button {
      right: 1rem;
      top: 1.75rem;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 1.25rem;
    }

    a {
      color: $cyprus;
      font-weight: 600;
      text-decoration: none;
      padding: 5px 0;
      display: flex;
      justify-content: left;
      align-items: left;
      font-size: unset;
    }
  }

  div {
    display: block;
  }
}

.mobileMenuTrigger {
  cursor: pointer;
  width: 24px;
  height: 20px;
  position: relative;

  &.hasBadge {
    span {
      &:nth-child(1) {
        width: calc(100% - 7px);
        margin-left: 7px;
      }
    }
  }

  &.mobileMenuTriggerActive {
    span {
      &:nth-child(1),
      &:nth-child(4) {
        top: 6px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &.transparent {
        background-color: $blue-extra-dark;
      }
    }

    .hamburgerFavoriteBadge {
      display: none;
    }
  }

  span {
    background-color: $blue-extra-dark;
    border-radius: 2px;
    display: block;
    height: 2px;
    left: 0%;
    position: absolute;
    transform: rotate(0deg);
    transition: $standardTransition;
    width: 100%;

    &.transparent {
      background-color: $white;
    }

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }

    &:nth-child(4) {
      top: 18px;
    }
  }
}

.searchIcon {
  height: 100%;
  width: 100%;
}

.bookingSteps {
  align-items: center;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.bookingStep {
  background-image: url('/assets/icons/icon-radioUnselectedGrey.svg');
  background-position: left;
  background-repeat: no-repeat;
  color: $grey-light;
  cursor: text;
  padding-left: 1.5rem;
  padding-right: 2rem;
}

.activeBookingStep {
  @extend .bookingStep;

  background-image: url('/assets/icons/icon-radioUnselected.svg');
  color: $casal;
  cursor: pointer;
}

.completedBookingStep {
  @extend .bookingStep;

  background-image: url('/assets/icons/icon-radioSelected.svg');
  color: $casal;
  cursor: pointer;
}

.needHelpBtn {
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.searchFields {
  display: flex;
  height: 100%;
  align-items: center;
  min-width: 80px;

  // Positioning mobile search icon
  @include listingPageOnly {
    @include mediaQuery(max, $mobile) {
      margin: 0 0 0 auto;
    }
  }

  @include favoritesPageOnly {
    @include mediaQuery(max, $mobile) {
      margin: 0 0 0 auto;
    }
  }
}

.userAvatarImg {
  width: auto;
  height: 3rem;
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
}

.hamburgerFavoriteBadge {
  width: 10px;
  height: auto;
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 1;
}

.linkFavoriteBadge {
  width: 7px;
  height: auto;
  position: relative;
  top: -5px;
  left: 2px;
  z-index: 1;
  max-height: 1rem;
}

.userMenuToggle {
  background: none;
  border: none;
  padding: 0px;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: auto;

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.headerMobileHamburgerBtn {
  background-color: transparent;
  border: none;
  height: 100%;
  margin-right: 1rem;
  padding: 0;
  top: 0;
  position: absolute;
  right: -1rem;
  @include noWebkitHilight;
  z-index: 9;

  &.searchPage {
    height: auto;
    @include mediaQuery(max, $searchPageMobile) {
      margin: 0.25rem 1rem;
    }
  }
}
